/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import Grid from '@material-ui/core/Grid';
import React from 'react';

const Fallback = (
  <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
    <div>Algo salió mal 😔</div>
  </Grid>
);

export default class extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    return this.state.hasError ? <Fallback /> : this.props.children;
  }
}
