import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// import AppLazy from './App';
import { AuthContextProvider } from './contexts/Auth';
import { SettingsContext, SettingsContextProvider } from './contexts/Settings';
import ErrorBoundary from './pages/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import theme from './Themes';

const AppLazy = React.lazy(() => import('./App'));

const Loading = () => (
  <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
    <CircularProgress />
    <Typography variant="h6" style={{ marginTop: 30 }}>
      Boxycargos...
    </Typography>
  </Grid>
);

const App = () => {
  const {
    settings: { themeColor },
  } = React.useContext(SettingsContext);

  return (
    <ThemeProvider theme={theme({ themeColor })}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <AppLazy />
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <SettingsContextProvider>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </SettingsContextProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
