const { REACT_APP_ENVIRONMENT } = process.env;

export const getCities = [
  {
    id: 'teodoro_schmidt',
    name: 'Teodoro Schmidt',
    enabled: true,
  },
  {
    id: 'barros_arana',
    name: 'Barros Arana',
    enabled: true,
  },
  {
    id: 'freire',
    name: 'Freire',
    enabled: true,
  },
  {
    id: 'padre_las_casas',
    name: 'Padre Las Casas',
    enabled: true,
  },
  {
    id: 'temuco',
    name: 'Temuco',
    enabled: true,
  },
  {
    id: 'hualpin',
    name: 'Hualpin',
    enabled: true,
  },
  {
    id: 'tolten',
    name: 'Toltén',
    enabled: true,
  },
  {
    id: 'queule',
    name: 'Queule',
    enabled: true,
  },
  {
    id: 'mehuin',
    name: 'Mehuin',
    enabled: true,
  },
  {
    id: 'san_jose_de_mariquina',
    name: 'San José de Mariquina',
    enabled: true,
  },
  {
    id: 'loncoche',
    name: 'Loncoche',
    enabled: true,
  },
  {
    id: 'nueva_imperial',
    name: 'Nueva Imperial',
    enabled: true,
  },
  {
    id: 'gorbea',
    name: 'Gorbea',
    enabled: true,
  },
  {
    id: 'pitrufquen',
    name: 'Pitrufquén',
    enabled: true,
  },
  {
    id: 'villarrica',
    name: 'Villarrica',
    enabled: true,
  },
  {
    id: 'pucon',
    name: 'Pucón',
    enabled: true,
  },
  {
    id: 'lautaro',
    name: 'Lautaro',
    enabled: true,
  },
  {
    id: 'victoria',
    name: 'Victoria',
    enabled: true,
  },
  {
    id: 'pto_saavedra',
    name: 'Puerto Saavedra',
    enabled: true,
  },
];

export const getReceptionType = [
  {
    name: 'home',
    label: 'Domicilio',
  },
];

export const getDeliveryType = [
  {
    name: 'office',
    label: 'Sucursal',
  },
  {
    name: 'home',
    label: 'Domicilio',
  },
];

export const getSettings = () => {
  return {
    notificationsEnabled: true,
    cost_per_km: 80,
    cost_minimum: 1000,
    cost_multi: 1100,
    sizes: [
      {
        size: 'S',
        width: 0,
        length: 15,
        height: 0,
        weight: 3,
        cost: 0,
      },
      {
        size: 'M',
        width: 0,
        length: 30,
        height: 0,
        weight: 5,
        cost: 1000,
      },
      {
        size: 'L',
        width: 0,
        length: 60,
        height: 0,
        weight: 10,
        cost: 2000,
      },
      {
        size: 'XL',
        width: 0,
        length: 90,
        height: 0,
        weight: 15,
        cost: 3000,
      },
      {
        size: 'Z',
        width: 0,
        length: 150,
        height: 0,
        weight: 16,
        cost: 5000,
      },
    ],
    textFieldSize: 'medium', // "small" || medium
    percentageExpress: 80,
    themeColor: REACT_APP_ENVIRONMENT === 'production' ? 'green' : 'orange', // "green" || orange
  };
};

export default {
  getCities,
  getSettings,
  getDeliveryType,
  getReceptionType,
};
