import React from 'react';

import { getSettings } from '../../helpers/settings';

interface SettingsContextInterface {
  settings: any;
  setSettings: any;
}

export const SettingsContext = React.createContext<SettingsContextInterface>({} as SettingsContextInterface);

export const SettingsContextProvider = React.memo((props: any) => {
  const settingsInitialState = getSettings();
  // console.log("settingsInitialState:", JSON.stringify(settingsInitialState, null, 4));

  const [settings, setSettings] = React.useState({ ...settingsInitialState });

  const contextual = React.useMemo(() => {
    return {
      settings,
      setSettings,
    };
  }, [settings]);

  return <SettingsContext.Provider value={contextual}>{props.children}</SettingsContext.Provider>;
});
