import { esES } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import React from 'react';

import { getSettings } from './helpers/settings';

const themeColorInitial = (getSettings().themeColor as 'green' | 'orange' | 'blue') || 'green';

const colors = {
  green: {
    palette: {
      type: 'light', // ["dark", "light"]
      primary: {
        main: '#619f04',
      },
      secondary: {
        main: '#619f04',
      },
      info: {
        main: '#567d2e',
      },
      background: {
        default: '#fff',
      },
    },
  },
  orange: {
    palette: {
      type: 'light', // ["dark", "light"]
      primary: {
        main: '#ef6c00',
      },
      secondary: {
        main: '#ef6c00',
      },
      background: {
        default: '#fff',
        paper: '#fff',
      },
      info: {
        main: '#af6200',
      },
    },
  },
  purple: {
    palette: {
      type: 'light', // ["dark", "light"]
      primary: {
        main: '#9c27b0',
      },
      secondary: {
        main: '#d500f9',
      },
      info: {
        main: '#8e24aa',
      },
    },
  },
  blue: {
    palette: {
      type: 'light', // ["dark", "light"]
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#3d5afe',
      },
    },
  },
};

export default function theme({ themeColor }: { themeColor?: 'green' | 'orange' | 'blue' }) {
  return React.useMemo(
    () =>
      createTheme(
        {
          ...(colors[themeColor || themeColorInitial] as any),
        },
        esES,
      ),
    [themeColor],
  );
}
