import jwt from 'jsonwebtoken';
import React from 'react';

const storegeKey = 'boxyAuth';

interface AuthContextInterface {
  auth: any;
  setAuth: any;
  onSuccess: any;
  onFailure: any;
  onLogoutSuccess: any;
  onLogoutFailure: any;
  selectedIndex: any;
  setSelectedIndex: any;
}

const { REACT_APP_JWT_SECRET_KEY = 'supersecret' } = process.env;
export const AuthContext = React.createContext<AuthContextInterface | null>(null);

const boxyAuth = localStorage.getItem(storegeKey);
const priorAuth = boxyAuth ? JSON.parse(boxyAuth) : {};
const startingAuth = boxyAuth ? { ...priorAuth, isAuthed: true } : {};

export const AuthContextProvider = React.memo((props: any) => {
  const [auth, setAuth] = React.useState(startingAuth);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const onSuccess = React.useMemo(() => {
    return (responseAuth: any) => {
      const { provider, data } = responseAuth;
      const response = { provider, ...data };
      // console.log('response:', response);
      if (response?.email) {
        const token = jwt.sign({ ...response }, REACT_APP_JWT_SECRET_KEY);
        localStorage.setItem(storegeKey, JSON.stringify({ ...response, token }));
        setAuth({ ...response, token, isAuthed: true });
      } else {
        const error = 'No autenticado';
        console.log('response:', { desc: error });
        setAuth({
          isAuthed: true,
          token: jwt.sign({ desc: error }, REACT_APP_JWT_SECRET_KEY),
        });
      }
    };
  }, [setAuth]);

  const onFailure = (response: any) => {
    console.error('login failure:', response);
    localStorage.removeItem(storegeKey);
    setAuth({ isAuthed: false });
  };

  const onLogoutSuccess = () => {
    localStorage.removeItem(storegeKey);
    setAuth({ isAuthed: false });
  };

  const onLogoutFailure = (response: any) => {
    console.error('logout failure:', response);
    localStorage.removeItem(storegeKey);
  };

  const contextual = React.useMemo(() => {
    return {
      auth,
      setAuth,
      onSuccess,
      onFailure,
      onLogoutSuccess,
      onLogoutFailure,
      selectedIndex,
      setSelectedIndex,
    };
  }, [auth, selectedIndex, onSuccess, onFailure]);

  return <AuthContext.Provider value={contextual}>{props.children}</AuthContext.Provider>;
});
